import { navigateTo } from '#app/composables/router';
import { useRoute } from 'vue-router';
export default defineNuxtRouteMiddleware(async () => {
  try {
    const token = document.cookie.split(';').find((c) => c.trim().startsWith('access_token='));
    if (token && useRoute().path !== '/adminasuser') return navigateTo({ path: '/mon-tableau-de-bord' });
    if (localStorage !== undefined && localStorage.getItem('user') !== undefined) {
      localStorage.removeItem('user');
    }
  } catch {
    return;
  }
});
